import React, { useRef, useState } from 'react';

import SimpleReactValidator from 'simple-react-validator';

import { useIntl, FormattedMessage } from '../../../plugins/publicis-gatsby-plugin-i18n';
import { Button } from '../../Molecules/Button/Button';
import Checkbox from '../../Molecules/Checkbox/Checkbox';
import Input from '../../Molecules/Input/Input';
import Textarea from '../../Molecules/Textarea/Textarea';

import './ContactForm.scss';
const axios = require('axios').default;

const ContactForm = ({ formName, langCode }) => {
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const [subject, setSubject] = useState('');
  const [lastname, setLastname] = useState('');
  const [firstname, setFirstname] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  //const [acceptCondition, setAcceptCondition] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const webFormSessionUrl = `${process.env.GATSBY_DRUPAL_BASE_URL}session/token`;
  const webFormSubmitUrl = `${process.env.GATSBY_DRUPAL_BASE_URL}webform_rest/submit?_format=json`;

  const intl = useIntl();

  const locale = {
    'accepted': intl.messages['common.form.error.accepted'],
    'alpha': intl.messages['common.form.error.alpha'],
    'alpha_space': intl.messages['common.form.error.alpha_space'],
    'boolean': intl.messages['common.form.error.boolean'],
    'email': intl.messages['common.form.error.email'],
    'numeric': intl.messages['common.form.error.numeric'],
    'phone': intl.messages['common.form.error.phone'],
    'regex': intl.messages['common.form.error.regex'],
    'required': intl.messages['common.form.error.required'],
    'string': intl.messages['common.form.error.string'],
    'typeof': intl.messages['common.form.error.typeof'],
  };

  SimpleReactValidator.addLocale(langCode, locale);

  const validator = useRef(new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate }, locale: langCode }));
  const handleSubmitForm = (e) => {
    e.preventDefault();

    if (validator.current.allValid()) {
      console.log('You submitted the form and stuff!');
      setIsError(false);
      axios.get(webFormSessionUrl).then(result => {
        if (200 === result.status) {
          const csrfToken = result.data;
          fetch(webFormSubmitUrl, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'X-CSRF-Token': csrfToken
            },
            body: JSON.stringify({
              'webform_id': formName,
              'surname': lastname,
              'name': firstname,
              'message': message,
              'email': email,
              'subject': subject
            }),
          })
            .then(response => response.json())
            .then(
              (data) => {
                //on success you do whatever you will want
                console.log('success', data);
                setIsEmailSent(true);
                window.scrollTo({ top: document.querySelector('.section_contact_form').offsetTop - 100, behavior: 'smooth' });
              },
              (error) => {
                console.log('error', error);
              }
            );
        }
      });
    } else {
      validator.current.showMessages();
      setIsError(true);
      //forceUpdate();
    }
  };

  return (
    <div className="contact_form_container">
      {isError &&
        <FormattedMessage id="common.form.required">{x => <span className="red">* {x}</span>}</FormattedMessage>
      }
      {
        isEmailSent &&
        <div className='email-sent'>
          <h3><FormattedMessage id="common.form.email_sent" /></h3>
        </div>
      }
      {!isEmailSent && (
        <form onSubmit={handleSubmitForm}>
          <Input
            validator={validator.current}
            field={{
              id: 'subject',
              filed_type: 'input',
              type: 'text',
              placeholder: intl.formatMessage({ id: 'contact.form.field.subject.placeholder' }),
              name: 'subject',
              label: intl.formatMessage({ id: 'contact.form.field.subject.label' }),
              value: subject
            }}
            reset={() => setSubject('')}
            change={(value) => setSubject(value)}
          />
          <Input
            validator={validator.current}
            field={{
              id: 'lastname',
              filed_type: 'input',
              type: 'text',
              placeholder: intl.formatMessage({ id: 'contact.form.field.lastname.placeholder' }),
              name: 'lastname',
              label: intl.formatMessage({ id: 'contact.form.field.lastname.label' }),
              value: lastname
            }}
            reset={() => setLastname('')}
            change={(value) => setLastname(value)}
          />
          <Input
            validator={validator.current}
            field={{
              id: 'firstname',
              filed_type: 'input',
              type: 'text',
              placeholder: intl.formatMessage({ id: 'contact.form.field.firstname.placeholder' }),
              name: 'firstname',
              label: intl.formatMessage({ id: 'contact.form.field.firstname.label' }),
              value: firstname
            }}
            reset={() => setFirstname('')}
            change={(value) => setFirstname(value)}
          />
          <Input
            validator={validator.current}
            field={{
              id: 'email',
              filed_type: 'input',
              type: 'email',
              placeholder: intl.formatMessage({ id: 'contact.form.field.email.placeholder' }),
              name: 'email',
              label: intl.formatMessage({ id: 'contact.form.field.email.label' }),
              value: email
            }}
            reset={() => setEmail('')}
            change={(value) => setEmail(value)}
          />
          <Textarea
            validator={validator.current}
            field={{
              id: 'message',
              filed_type: 'textarea',
              type: 'textarea',
              placeholder: intl.formatMessage({ id: 'contact.form.field.message.placeholder' }),
              name: 'message',
              label: intl.formatMessage({ id: 'contact.form.field.message.label' }),
              value: message
            }}
            reset={() => { setMessage('') }}
            change={(value) => { setMessage(value) }}
          />
          {/* <Checkbox
            validator={validator.current}
            field={{
              id: 'accept_checkbox',
              filed_type: 'checkbox',
              type: 'checkbox',
              name: '?????',//ReturnTranslation('form-accept', langCode),
              label: 'C\'est quoi ce champ ?????', //ReturnTranslation('form-accept', langCode) + '*',
              value: acceptCondition
            }}
            change={(id, value) => setAcceptCondition(value)}
          /> */}
          <div className="btn_container">
            <Button
              id="submit"
              primary={true}
              label={intl.formatMessage({ id: 'contact.cta.submit' })}
              onClick={handleSubmitForm}
            />
          </div>
        </form>
      )}
    </div>
  );
};

export default ContactForm;
