import * as React from 'react';

import { CloseIcon } from '../../Atoms/Icons/Icons';

import './input.scss';
let classNames = require('classnames');

class Input extends React.Component {
  constructor(props) {
    super(props);
    this.onReset = this.onReset.bind(this);
  }

  onReset(e) {
    e.preventDefault();
    this.props.reset();
  }

  render() {
    const { change, validator, field, optional } = this.props;
    let params = !optional ? 'required|' : '';
    if (field.type === 'email') {
      params += 'email';
    }
    const msg_error = validator.message(field.id, field.value, params, { className: 'error' });
    return (
      <div className={classNames('input', field.customClass, { 'error_exist': msg_error })}>
        <label>{field.label}</label>
        <div className="input_wrap">
          <input className={classNames({ 'value_exist': field.value.length > 0 })} type={field.type} name={field.name} value={field.value} placeholder={field.placeholder} onChange={(e) => change(e.currentTarget.value)} />
          {
            msg_error && (
              msg_error
            )
          }
          {
            field.value.length > 0 && (
              <button className="clear" onClick={this.onReset} tabIndex="-1"><CloseIcon /></button>
            )
          }
        </div>
      </div>
    );
  }
}

Input.defaultProps = {
  label: 'Sujet de votre email',
  name: 'email',
  value: undefined,
  placeholder: 'Le sujet de votre eMail',
  type: 'alpha', /* https://www.npmjs.com/package/simple-react-validator#rules */
  optional: false
};

export default Input;
