import * as React from 'react';

import { CloseIcon } from '../../Atoms/Icons/Icons';

import './Textarea.scss';
let classNames = require('classnames');

class Textarea extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.value || undefined,
      params: !this.props.optional ? 'required|' : '' + this.props.type
    };
    this.onReset = this.onReset.bind(this);
  }

  onReset(e) {
    e.preventDefault();
    this.props.reset();
  }

  render() {
    const { validator, field, change, optional } = this.props;
    const params = !optional ? 'required|' : '';

    return (
      <div className={classNames(['textarea', field.customClass])}>
        <label>{field.label}</label>
        <div className="input_wrap">
          <textarea className={classNames({ 'value_exist': field.value.length > 0 })} name={field.name} value={field.value} placeholder={field.placeholder} onChange={(e) => change(e.currentTarget.value)} />
          {validator.message(field.id, field.value, params, { className: 'error' })}
          {
            field.value.length > 0 && (
              <button className="clear" onClick={this.onReset} tabIndex="-1"><CloseIcon /></button>
            )
          }
        </div>
      </div>
    );
  }
}

Textarea.defaultProps = {
  label: 'Sujet de votre email',
  name: 'email',
  value: undefined,
  placeholder: 'Le sujet de votre eMail',
  type: 'alpha', /* https://www.npmjs.com/package/simple-react-validator#rules */
  optional: false
};

export default Textarea;
