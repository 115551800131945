import React, { useState } from "react";
import './TitleContact.scss';
var classNames = require('classnames');

export const TitleContact = ({ ...props }) => {
    return (
        <h2 className={classNames(["title-deco", props.class])}>{props.title}</h2>
    );
  };

TitleContact.defaultProps = {
    title: "Investisseurs"
};  

export default TitleContact;